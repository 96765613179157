.messages {
	visibility: visible;
	position: fixed;
	z-index: 1100;
	margin: 0;
	padding: 0;
	text-align: center;
	top: 60px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	right: 2.5%;
}

.messages__message {
	margin: 2px;
	padding: 7px 20px 7px 35px;
	max-width: 300px;
	list-style-type: none;
	transition: opacity ease-out;
	font-size: 12px;
	font-weight: 700;
	opacity: 0;
	animation: fadeMessage 5s;
	overflow-wrap: break-word;
	border-radius: 3px;
	color: #ffffff;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	position: relative;
	&:before {
		position: absolute;
		left: 20px;
		top: 7px;
		margin-right: 20px;
		font-family: 'font awesome 5 free';
		display: inline;
		font-weight: 900;
	}
}
.messages__message--error {
	background-color: #F32C52;
	color: white;
	&:before {
		content: '\f05e';
	}
}
.messages__message--info {
	background-color: #416dea;
	color: white;
	box-shadow: 2px 5px 10px #e4e4e4;
	&:before {
		content: '\f05a';
	}
}
.messages__message--warning {
	background-color: #feee7d;
	color: #f15c5c;
	&:before {
		content: '\f071';
	}
}
.messages__message--success {
	background-color: #3dd28d;
	color: #ffffff;
	&:before {
		content: '\f058';
	}
}


@keyframes fadeMessage {
	0% {
    	opacity: 1;
	}
	70% {
	    opacity: 1;
	}
	100% {
	    opacity: 0;
	}
}
